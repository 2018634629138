<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" :title="$t('message.support_title')" icon="fa-solid fa-user-headset" />

    <div class="generalBorderlessBox">
        <div class="row" style="margin-bottom: 45px">
            <div class="col-12 col-lg-8 d-grid">
                <div class="generalContentBox border shadow-sm px-4">
                    <div class="row">

                        <!-- lang html tag example 
                        <div class="col-12 pb-3 bg-warning">
                            <div v-html="$t('message.hello', { name: 'John@testing.com' })"></div>
                        </div> -->
                        
                        <div class="col-12 pb-3">
                             <div>{{ $t('message.support_title_desc') }}</div>
                        </div>

                        <!-- <div class="col-12 pb-3 text-grey fst-italic">
                            For faster resolution, kindly include relevant information such as your contact detail, error messages, and any screenshots if applicable.
                        </div> -->

                        <div class="col-12 col-lg-6 pb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="displayName" id="lblDisplayName" :placeholder="$t('message.all_lbl_display_name')">
                                <label for="lblDisplayName">{{ $t('message.all_lbl_display_name') }}</label>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="email" id="lblEmail" :placeholder="$t('message.support_lbl_contact_email')">
                                <label for="lblEmail">{{  $t('message.support_lbl_contact_email') }}</label>
                            </div>
                        </div>

                        <div class="col-12 pb-3">
                            <div class="form-floating">
                                <input type="text" class="form-control" v-model="subject" id="lblSubject" :placeholder="$t('message.support_lbl_subject')">
                                <label for="lblSubject">{{ $t('message.support_lbl_subject') }}</label>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3 d-none d-sm-block"></div>

                        <div class="col-12 pb-3">
                            <div class="form-floating">
                                <textarea class="form-control" v-model="message" id="lblMessage" style="height: 150px"></textarea>
                                <label for="lblMessage">{{  $t('message.support_lbl_issue_inquiry') }}</label>
                            </div>
                        </div>

                        <!-- <div class="col-12 pb-3">
                            <div class="mb-1 isLink">
                                <i class="fa-solid fa-paperclip me-2"></i>Screenshot(1).png
                            </div>
                            <div class="mb-1 isLink">
                                <i class="fa-solid fa-paperclip me-2"></i>ABC booked.pdf
                            </div>
                        </div> -->

                        <div class="col-12 text-center py-2">
                            <!-- <button class="btn btn-outline-secondary mb-2 me-2" data-bs-toggle="modal" data-bs-target="#mdlAddAttachment">
                                <i class="fa-solid fa-paperclip fa-lg me-2"></i>Upload screenshot
                            </button> -->
                            <button class="btn btn-info text-white mb-2" @click="submit">
                                <i class="fa-solid fa-envelope fa-lg me-2"></i>{{ $t('message.all_btn_submit') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-4 d-grid">
                <div class="border shadow-sm generalContentBox" style="position: relative; height: 95%">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <span class="text-info isLink float-end" data-bs-toggle="modal" data-bs-target="#mdlAddAttachment">
                                <i class="fa-solid fa-paperclip fa-lg me-2"></i>{{ $t('message.support_lnk_upload_screenshot_tc') }}
                            </span>
                        </div>

                        <div class="col-12 mb-2">
                            <div><i class="fa-solid fa-image me-2"></i>Screenshot(1).png</div>
                            <div>
                                <div class="bg-light" style="height: 150px">
                                    <div class="text-center pt-5">Sample Image<br/>Preview</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mb-2">
                            <div><i class="fa-solid fa-image me-2"></i>Screenshot(2).png</div>
                            <div>
                                <div class="bg-light" style="height: 150px">
                                    <div class="text-center pt-5">Sample Image<br/>Preview</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mb-2 isLink">
                            <i class="fa-solid fa-file-pdf me-2"></i>ABC booked.pdf
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal - Add attachment --> 
    <div
        class="modal fade"
        id="mdlAddAttachment"
        tabindex="-1"
        aria-labelledby="addAttachment"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ $t('message.support_lnk_upload_screenshot') }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <div v-if="isUploading === true" class="mb-2">
                            <fa icon="spinner" size="lg" class="me-2" />{{ $t('message.all_loading') }}
                        </div>

                        <file-pond
                            name="file"
                            class="attachm"
                            ref="pondAttachm"
                            label-idle="Drop image"
                            v-bind:allow-multiple="false"
                            accepted-file-types="image/jpg, image/jpeg, image/png, image/gif, application/pdf"
                            :server="attachmConfig" 
                            :files="myAttachm"
                            storeAsFile="true"
                            @init="handleAttachmPondInit"
                            @error="uplAttachmError"
                            @processfile="onprocessAttachm"
                            allowRevert="true"
                            allowRemove="false"
                        />
                        <div class="text-center small text-grey pb-5">
                            {{ $t('message.all_img_max_1mb') }} <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> {{ $t('message.all_img_type_pdf') }}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <i class="fa-solid fa-xmark me-2"></i>{{ $t('message.all_btn_close') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useI18n } from 'vue-i18n'
import funcs from '@/functions/function'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'


// Image Uploader for avatar
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
    name: 'Support',
    components: { Alert, TopNavigation, FilePond, useI18n },
    setup () {
        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()
        const { getUserId, getDisplayName } = useStore()
        const { t } = useI18n()

        const isLogin = ref(false)
        const displayName = ref(null)
        const email = ref(null)
        const subject = ref(null)
        const message = ref(null)

        const isUploading = ref(false)
        const pondAttachm = ref(null)

        const attachmConfig = ref({ 
            "url": axios.defaults.baseURL + "support/attachment/upload", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const submit = () => {
            alert.value.push({
                class: "success",
                title: "SUCCESS",
                message: t('message.support_title_desc')
            })
        }

        const chkSession = async () => {
            isLogin.value = false
            
            try {
                const res = await axios.get('/auth/session')

                if( res.data.status === 1) {
                    isLogin.value = true
                   
                } else {
                    isLogin.value = false
                    
                }

                if (isLogin.value === true && getUserId.value !== null && getUserId.value !== undefined) {
                    email.value = getUserId.value
                }

                if (isLogin.value === true && getDisplayName.value !== null && getDisplayName.value !== undefined) {
                    displayName.value = getDisplayName.value
                }

            } catch (error) {
                isLogin.value = false
                func.addLog('plan', 'chkSession - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                // console.info('plan err', error)
            }
        }

        const uplAttachmError = (error) => {
            func.addLog('support', 'uplAttachmError', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
        }

        const onprocessAttachm = (err, file) => {
            if (err === null) {
                pondAttachm.value.removeFile(file) // Remove preview image after uploaded complete
                // getHistoryList('initial')
                pondAttachm.value.removeFile(file) // Remove preview image after uploaded complete

            }
        }

        onMounted(() => {
            chkSession()

            const aInit = document.querySelector('.attachm')
            aInit.addEventListener('change', (event) => {
                isUploading.value = true
                const selectedfile = event.target.files

                if (selectedfile.length > 0) {
                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasAContextSrc.value = srcData

                    }
                    fileReader.readAsDataURL(imageFile)

                }

            })

            // Detect drop 
            const aInit2 = document.querySelector('.attachm')
            aInit2.addEventListener('drop', (event) => {
                isUploading.value = true
                event.preventDefault()
                const selectedfile = event.dataTransfer.files
                if (selectedfile.length > 0) {

                    const [imageFile] = selectedfile
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        const srcData = fileReader.result
                        canvasAContextSrc.value = srcData

                    }
                    fileReader.readAsDataURL(imageFile)

                }

            })
            
        })


        return {
            axios, func, route, router, store, alert, closeAlert, isLogin, getUserId, getDisplayName,
            displayName, email, subject, message, isUploading, pondAttachm, uplAttachmError, onprocessAttachm, attachmConfig,
            submit
        }
    },
    data () {
        return {
            myAttachm: []
        }
    },
    methods: {
        handleAttachmPondInit (){
            // console.info('FilePond has initialized')
            // example of instance method call on pond reference
            this.$refs.pondAttachm.getFiles()
        }
    }
}
</script>

<style>
    
</style>